import * as React from 'react';
import '../styles/global.css';
import Kiku16 from '../components/common/icons/Kiku16';
import Kiku18 from '../components/common/icons/Kiku18';
import KikuLayered from '../components/common/icons/KikuLayered';

const IndexPage = () => {
  return (
    <main>
      <title>Test Page</title>
      <Kiku16 fill="red" stroke="gold" circleFill="white" size={100} />
      <Kiku18 fill="red" stroke="gold" circleFill="white" size={100} />
      <KikuLayered fill="white" stroke="gold" fill2="red" stroke2="gold" circleFill="white" size={100} />
    </main>
  )
}

export default IndexPage
